import { useEffect, useState, useContext, useRef } from "react";
import "./MainGrid.css";
import GridComp from "../GridComps/GridComp";
import SudokuContext from "../SudokuContext";
import checkForError from "../MainGrid/Scripts";
import NumPadPopup from "../NumPad.js/NumPadPopup";

function MainGrid() {
  const { grid, setGrid, selectionGrid, setSelectionGrid, setting } =
    useContext(SudokuContext);

  // Cell Selection - Alle deselektiert (false)
  const initialSelectionGrid = Array(9)
    .fill(null)
    .map(() => Array(9).fill(false));

  const [isMouseDown, setIsMouseDown] = useState(false);

  // Prüft bei jeder Änderung, ob ein Fehler gemacht wurde
  useEffect(() => {
    checkForError(grid, setGrid);
  }, [JSON.stringify(grid)]);

  useEffect(() => {
    function handleMouseDown(event) {
      // Selektieren mit Mausklick
      // shift | ctrl verhindern reset des selectionGrid
      const validClick =
        (event.srcElement.id >= 0 &&
          event.srcElement.id <= 88 &&
          event.srcElement.id != "") ||
        event.srcElement.className === "ColorButton__container" ||
        event.srcElement.className === "NumPad_Digit" ||
        event.srcElement.parentElement.className === "NumPadBar" ||
        event.srcElement.parentElement.className === "NumPad_Grid_Delete";
      if (validClick) {
        event.preventDefault();
        setIsMouseDown(true);
        let reset = true;
        if (event.shiftKey || event.ctrlKey) {
          reset = false;
        }
        handleSelection(event, reset);
      } else {
        setSelectionGrid(initialSelectionGrid);
      }
    }

    function handleMouseMove(event) {
      // Selektion mit gedrückter Maustaste
      if (isMouseDown) {
        let reset = false;
        handleSelection(event, reset);
      }
    }

    function handleMouseUp() {
      setIsMouseDown(false);
    }

    function handleSelection(event, reset) {
      // Ausgewählte Zelle dem selectionGrid hinzufügen
      // Ohne shift | ctrl wird es zuerst resettet
      const id = event.srcElement.id;
      let newSelectionGrid;
      if (id != "" && Number(id) >= 0 && Number(id) <= 88) {
        const [idx_row, idx_col] = id.split("");

        if (reset == true) {
          newSelectionGrid = initialSelectionGrid.map((row) => [...row]);
        } else {
          newSelectionGrid = selectionGrid.map((row) => [...row]);
        }

        newSelectionGrid[idx_row][idx_col] = true;

        setSelectionGrid(newSelectionGrid);
      } else if (id == "root") {
        newSelectionGrid = initialSelectionGrid.map((row) => [...row]);
        setSelectionGrid(newSelectionGrid);
      }
    }

    function handleKeyDown(event) {
      // Zahleneingaben in Zellen schreiben für ausgewählte Zellen
      // shift -> corner number
      // ctrl -> center number
      event.preventDefault();
      const key = parseInt(event.code.charAt(event.code.length - 1));
      let newGrid;
      if (key >= 1 && key <= 9) {
        const shift = event.shiftKey;
        const ctrl = event.ctrlKey;
        // Corner Numbers
        if (shift == true) {
          newGrid = grid.map((row, idx_row) =>
            row.map((cell, idx_col) => {
              if (selectionGrid[idx_row][idx_col] == true) {
                cell.setCorner(Number(key), setting);
              }
              return cell;
            })
          );
          // Center Numbers
        } else if (ctrl == true) {
          newGrid = grid.map((row, idx_row) =>
            row.map((cell, idx_col) => {
              if (selectionGrid[idx_row][idx_col] == true) {
                cell.setCenter(Number(key), setting);
              }
              return cell;
            })
          );
        } else {
          // State Number
          newGrid = grid.map((row, idx_row) =>
            row.map((cell, idx_col) => {
              if (selectionGrid[idx_row][idx_col] == true) {
                cell.setState(Number(key), setting);
              }
              return cell;
            })
          );
        }
        setGrid(newGrid);
      } else if (event.key == "Backspace" || event.key == "Delete") {
        // Einträge löschen mit Del | Backspace
        newGrid = grid.map((row, idx_row) =>
          row.map((cell, idx_col) => {
            if (selectionGrid[idx_row][idx_col] == true) {
              cell.clearAll(setting);
            }
            return cell;
          })
        );
        setGrid(newGrid);
      }
    }

    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectionGrid]);

  // Popup Numpad

  const [isToggled, setIsToggled] = useState(false);
  function handleContextMenu(event) {
    event.preventDefault();
    setIsToggled(true);
  }

  return (
    <div className="MainGrid__container">
      <GridComp grid={grid} selection={{ selectionGrid, setSelectionGrid }} />
      {/* {isToggled ? <NumPadPopup /> : ""} */}
    </div>
  );
}

export default MainGrid;
