import React, { useState, useContext } from "react";
import "./NumPad.css";
import NumPadBar from "./NumPadBar";
import NumPadDigit from "./NumPadDigit";
import NumPadPopup from "./NumPadPopup";
import SudokuContext from "../SudokuContext";
import useViewportWidth from "../Hooks/useViewport";

function NumPad() {
  const Digits = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const { mode, setMode, grid, setGrid, selectionGrid, setting } =
    useContext(SudokuContext);
  const viewportWidth = useViewportWidth();

  function handleDelete() {
    const newGrid = grid.map((row, idx_row) =>
      row.map((cell, idx_col) => {
        if (selectionGrid[idx_row][idx_col] == true) {
          cell.clearAll(setting);
        }
        return cell;
      })
    );
    setGrid(newGrid);
  }

  function NumPadDesktop() {
    return (
      <div className="NumPad__container">
        {/* <NumPadPopup /> */}
        <NumPadBar mode={mode} setMode={setMode} />
        <div className="NumPad_Grid">
          <div className="NumPad_Grid_Numbers">
            {Digits.map((digit, index) => (
              <NumPadDigit key={index} number={digit} mode={mode} />
            ))}
          </div>
          <div
            className="NumPad_Grid_Delete"
            onClick={() => {
              handleDelete();
            }}
          >
            <button>X</button>
          </div>
        </div>
      </div>
    );
  }

  function NumPadMobile() {
    return (
      <div className="NumPad__container">
        <NumPadBar mode={mode} setMode={setMode} />
        <div className="NumPad_Grid">
          <div className="NumPad_Grid_Numbers">
            {Digits.map((digit, index) => (
              <NumPadDigit key={index} number={digit} mode={mode} />
            ))}
            <div
              className="NumPad_Grid_Delete"
              onClick={() => {
                handleDelete();
              }}
            >
              <button>X</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <>{viewportWidth < 1040 ? <NumPadMobile /> : <NumPadDesktop />}</>;
}

export default NumPad;
