import React, { useContext } from "react";
import SudokuContext from "../SudokuContext";

function NumPadDigit({ number, mode }) {
  const { grid, setGrid, selectionGrid, setting } = useContext(SudokuContext);
  const Digits = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  function handleClick(event) {
    const key = number;
    const shift = event.shiftKey || mode[1] ? true : false;
    const ctrl = event.ctrlKey || mode[2] ? true : false;

    let newGrid;

    if (shift === true) {
      newGrid = grid.map((row, idx_row) =>
        row.map((cell, idx_col) => {
          if (selectionGrid[idx_row][idx_col] === true) {
            cell.setCorner(Number(key), setting);
          }
          return cell;
        })
      );
      // Center Numbers
    } else if (ctrl === true) {
      newGrid = grid.map((row, idx_row) =>
        row.map((cell, idx_col) => {
          if (selectionGrid[idx_row][idx_col] === true) {
            cell.setCenter(Number(key), setting);
          }
          return cell;
        })
      );
    } else {
      // State Number
      newGrid = grid.map((row, idx_row) =>
        row.map((cell, idx_col) => {
          if (selectionGrid[idx_row][idx_col] === true) {
            cell.setState(Number(key), setting);
          }
          return cell;
        })
      );
    }
    setGrid(newGrid);
  }
  return (
    <div
      className="NumPad_Digit"
      onClick={(event) => {
        handleClick(event);
      }}
    >
      {number}
    </div>
  );
}

export default NumPadDigit;
