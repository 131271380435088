import React, { useContext, useEffect, useState } from "react";
import "./Database.css";
import SudokuContext from "../SudokuContext";
import useGrid from "../Hooks/useGrid";
import { string2grid } from "../globalScripts/string2grid";
import Stopwatchcounter from "./Stopwatchcounter";

function Database() {
  const [newGrid, setNewGrid] = useGrid();
  const { setGrid, setSetting } = useContext(SudokuContext);
  const [counterReset, setCounterReset] = useState(false);

  // SQL FUNCTION
  const getSQLSudoku = async (grade_) => {
    await fetch("/api/loadSudoku" + grade_ + ".php")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        const sudokuString = data[0].sudoku_string;
        setGrid(string2grid(sudokuString, newGrid));
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    loadSudoku("medium");
  }, []);

  function loadSudoku(grade_) {
    resetTimer();
    getSQLSudoku(grade_);
  }

  function resetTimer() {
    setCounterReset(true);
  }

  return (
    <div className="Database__container">
      <button
        className="Database_Mode"
        id="FirstLetter"
        onClick={() => {
          loadSudoku("easy");
        }}
      >
        Easy
      </button>
      <button
        className="Database_Mode"
        id="FirstLetter"
        onClick={() => {
          loadSudoku("medium");
        }}
      >
        Medium
      </button>
      <button
        className="Database_Mode"
        id="FirstLetter"
        onClick={() => {
          loadSudoku("hard");
        }}
      >
        Hard
      </button>
      <div className="Database_Timer">
        <Stopwatchcounter
          counterReset={counterReset}
          setCounterReset={setCounterReset}
        />
      </div>
    </div>
  );
}

export default Database;
