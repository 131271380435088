import "./Sudoku_Apps.css";
import React from "react";
import MainGrid from "../MainGrid/MainGrid";
import ColorPalette from "../ColorPalette/ColorPalette";
import useGrid from "../Hooks/useGrid";
import useSelection from "../Hooks/useSelection";
import SudokuContext from "../SudokuContext";
import NumPad from "../NumPad.js/NumPad";
import SettingBar from "../SettingBar/SettingBar";
import History from "../History/History";
import Import from "../Import/Import";
import Solver from "../Solver/Solver";
import Database from "../Database/Database";
import { useState } from "react";

function Sudoku_Preset() {
  const [grid, setGrid] = useGrid();
  const [selectionGrid, setSelectionGrid] = useSelection();
  const [setting, setSetting] = useState(false); // Setting / Solving Mode
  const [mode, setMode] = useState([true, false, false]); // Number, Corner, Center
  return (
    <div className="Sudoku_App__container">
      <SudokuContext.Provider
        value={{
          grid,
          setGrid,
          selectionGrid,
          setSelectionGrid,
          setting,
          setSetting,
          mode,
          setMode,
        }}
      >
        <div className="Sudoku_App_Maingrid">
          <Database />
          <MainGrid />
        </div>
        <div className="Sudoku_App_Toolbar">
          <NumPad />
          <ColorPalette />
          {/* <History /> */}
          {/* <Solver /> */}
        </div>
      </SudokuContext.Provider>
    </div>
  );
}

export default Sudoku_Preset;
