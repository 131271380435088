import React, { useState } from "react";

function NumPadBar({ mode, setMode }) {
  const primaryRGB = "rgba(36,105,69,1)";
  const blackRGB = "rgba(0,0,0,1)";
  const whiteRGB = "rgba(255,255,255,1)";

  const selectedDiv = { backgroundColor: primaryRGB, color: whiteRGB };
  const notSelectedDiv = { backgroundColor: whiteRGB, color: blackRGB };

  function handleModeSwitch(mode) {
    switch (mode) {
      case "number":
        setMode([true, false, false]);
        break;
      case "corner":
        setMode([false, true, false]);
        break;
      case "center":
        setMode([false, false, true]);
        break;
    }
  }

  return (
    <div className="NumPadBar">
      <button
        id="NumPadBar_Number"
        onClick={() => {
          handleModeSwitch("number");
        }}
        style={mode[0] ? selectedDiv : notSelectedDiv}
      >
        Number
      </button>
      <button
        id="NumPadBar_Corner"
        onClick={() => {
          handleModeSwitch("corner");
        }}
        style={mode[1] ? selectedDiv : notSelectedDiv}
      >
        Corner
      </button>
      <button
        id="NumPadBar_Center"
        onClick={() => {
          handleModeSwitch("center");
        }}
        style={mode[2] ? selectedDiv : notSelectedDiv}
      >
        Center
      </button>
    </div>
  );
}

export default NumPadBar;
