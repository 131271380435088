import React, { useContext } from "react";
import "./ColorPalette.css";
import SudokuContext from "../SudokuContext";

function ColorPalette() {
  const { grid, setGrid, selectionGrid } = useContext(SudokuContext);

  function ColorButton(props) {
    const { color } = props;
    const style = { backgroundColor: color };

    function setColor() {
      const newGrid = grid.map((row, idx_row) =>
        row.map((cell, idx_col) => {
          if (selectionGrid[idx_row][idx_col] == true) {
            cell.setColor(color);
          }
          return cell;
        })
      );
      setGrid(newGrid);
    }

    return (
      <div
        className="ColorButton__container"
        style={style}
        onClick={() => {
          setColor();
        }}
      ></div>
    );
  }
  return (
    <div className="ColorPalette__container">
      <ColorButton color="rgba(255,255,255,1)" />
      <ColorButton color="rgba(230,230,230,1)" />
      <ColorButton color="rgba(81,81,81,1)" />
      <ColorButton color="rgba(83,145,174,1)" />
      <ColorButton color="rgba(133,180,100,1)" />
      <ColorButton color="rgba(226,210,105,1)" />
      <ColorButton color="rgba(234,145,94,1)" />
      <ColorButton color="rgba(188,103,123,1)" />
      <ColorButton color="rgba(122,78,138,1)" />
    </div>
  );
}

export default ColorPalette;
