import React from "react";
import "./BoxComp.css";
import CellComp from "./CellComp";

function BoxComp(props) {
  const { subBox, selection } = props;

  const subCells = subBox.flat();

  return (
    <div className="BoxComp__container">
      {subCells.map((subCell, index) => (
        <CellComp key={index} subCell={subCell} selection={selection} />
      ))}
    </div>
  );
}

export default BoxComp;
