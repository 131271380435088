import React from "react";
import "./GridComp.css";
import BoxComp from "./BoxComp";

function GridComp(props) {
  const { grid, selection, refresh } = props;

  const subBoxes = [];

  for (let i = 0; i < 9; i += 3) {
    for (let j = 0; j < 9; j += 3) {
      const subBox = [];

      for (let k = i; k < i + 3; k++) {
        for (let l = j; l < j + 3; l++) {
          subBox.push(grid[k][l]);
        }
      }

      subBoxes.push(subBox);
    }
  }

  return (
    <div className="GridComp__container">
      {subBoxes.map((subBox, index) => (
        <BoxComp
          key={index}
          subBox={subBox}
          selection={selection}
          refresh={refresh}
        />
      ))}
    </div>
  );
}

export default GridComp;
