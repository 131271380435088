function checkForError(grid, setGrid) {
  const newGrid = grid.map((row) => [...row]);
  const errors = [];

  // Alle Error auf false
  for (let i = 0; i < 9; i++) {
    for (let j = 0; j < 9; j++) {
      newGrid[i][j].isError = false;
    }
  }

  // check rows
  for (let i = 0; i < 9; i++) {
    const row = [];
    for (let j = 0; j < 9; j++) {
      row.push(grid[i][j]);
    }
    checkSet(row);
  }

  // check columns
  for (let i = 0; i < 9; i++) {
    const column = [];
    for (let j = 0; j < 9; j++) {
      column.push(grid[j][i]);
    }
    checkSet(column);
  }

  // check box
  for (let i = 0; i < 9; i += 3) {
    for (let j = 0; j < 9; j += 3) {
      const subBox = [];

      for (let k = i; k < i + 3; k++) {
        for (let l = j; l < j + 3; l++) {
          subBox.push(grid[k][l]);
        }
      }
      checkSet(subBox);
    }
  }

  // Error Array durchgehen und alle markieren
  for (let i = 0; i < errors.length; i++) {
    newGrid[errors[i][0]][errors[i][1]].isError = true;
  }
  setGrid(newGrid);

  function checkSet(set_) {
    // Überprüft das Set (Zeile, Spalte, Box) ob doppelte Zahlen enthalten sind
    // Doppelte Einträge werden zu errors hinzugefügt
    for (let o = 0; o < 9; o++) {
      const refNumber = set_[o].state;
      if (refNumber !== null) {
        for (let n = 0; n < 9; n++) {
          if (n !== o && refNumber === set_[n].state) {
            errors.push(set_[n].index);
          }
        }
      }
    }
  }
}

export default checkForError;
