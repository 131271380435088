import "./App.css";

import PresetPage from "./Pages/PresetPage/PresetPage";
import CustomPage from "./Pages/CustomPage/CustomPage";
import SolverPage from "./Pages/SolverPage/SolverPage";
import CreatorPage from "./Pages/CreatorPage/CreatorPage";
import AnalyzerPage from "./Pages/AnalyzerPage/AnalyzerPage";
import Sudoku_Preset from "./Sudoku_Components/Sudoku_Apps/Sudoku_Preset";
import Header from "./Header/Header";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App__container">
      <Header />
      <div className="App_Sudoku_App">
        <Routes>
          <Route path="/" element={<PresetPage />} />
          <Route path="/Custom" element={<CustomPage />} />
          <Route path="/Creator" element={<CreatorPage />} />
          <Route path="/Analyzer" element={<AnalyzerPage />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
