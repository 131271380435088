import React, { useContext } from "react";
import "./SettingBar.css";
import SudokuContext from "../SudokuContext";

function SettingBar() {
  const { selectionGrid, setSelectionGrid, setting, setSetting } =
    useContext(SudokuContext);

  const primaryRGB = "rgba(36,105,69,1)";
  const blackRGB = "rgba(0,0,0,1)";
  const whiteRGB = "rgba(255,255,255,1)";

  const selectedDiv = { backgroundColor: primaryRGB, color: whiteRGB };
  const notSelectedDiv = { backgroundColor: whiteRGB, color: blackRGB };

  function switchSetting(setting_) {
    setSetting(setting_);
    const newSelection = selectionGrid.map((row, idx_row) =>
      row.map((cell, idx_col) => false)
    );
    setSelectionGrid(newSelection);
  }

  return (
    <div className="SettingBar__container">
      <button
        id="SettingBar_Setting"
        onClick={() => {
          switchSetting(true);
        }}
        style={setting ? selectedDiv : notSelectedDiv}
      >
        Setting
      </button>
      <button
        id="SettingBar_Solving"
        onClick={() => {
          switchSetting(false);
        }}
        style={setting ? notSelectedDiv : selectedDiv}
      >
        Solving
      </button>
    </div>
  );
}

export default SettingBar;
