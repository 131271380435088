import { getNakedSingles } from "./nakedSingle";
import { getHiddenSingles } from "./hiddenSingles";
import { getNakedPairs } from "./nakedPairs";
import { getDirectClaiming } from "./directClaiming";
import { getHiddenPairs } from "./hiddenPairs";
import { getDirectPointing } from "./directPointing";
import { getXWing } from "./XWing";
import { initialCandidates, updateCandidates } from "./updateCandidates";
import { getFreeCells } from "./getFreeCells";
import { cloneDeep } from "lodash";

export function handleStep(newGrid, stats) {
  let flag, stepScore;

  newGrid = updateCandidates(newGrid);

  [newGrid, flag, stepScore] = getHiddenSingles(newGrid);
  if (flag) {
    console.log("Hidden Single");
    stats.addHiddenSingle();
    return { grid: newGrid, score: stepScore, finished: false };
  }

  [newGrid, flag, stepScore] = getDirectPointing(newGrid);
  if (flag) {
    console.log("Direct Pointing");
    stats.addDirectPointing();
    return { grid: newGrid, score: stepScore, finished: false };
  }

  [newGrid, flag, stepScore] = getDirectClaiming(newGrid);
  if (flag) {
    console.log("Direct Claiming");
    stats.addDirectClaiming();
    return { grid: newGrid, score: stepScore, finished: false };
  }

  [newGrid, flag, stepScore] = getNakedSingles(newGrid);
  if (flag) {
    console.log("Naked Single");
    stats.addNakedSingle();
    return { grid: newGrid, score: stepScore, finished: false };
  }

  [newGrid, flag, stepScore] = getNakedPairs(newGrid);
  if (flag) {
    console.log("Naked Pair");
    stats.addNakedPair();
    return { grid: newGrid, score: stepScore, finished: false };
  }

  [newGrid, flag] = getHiddenPairs(newGrid);
  if (flag) {
    console.log("Hidden Pair");
    return { grid: newGrid, score: 1, finished: false };
  }

  [newGrid, flag] = getXWing(newGrid);
  if (flag) {
    console.log("X Wing");
    return { grid: newGrid, score: 1, finished: false };
  }

  return { grid: newGrid, score: 0, finished: true };
}

function isSolved(grid_) {
  // Überprüft, ob das grid gelöst ist
  for (let row = 0; row < 9; row++) {
    for (let col = 0; col < 9; col++) {
      if (grid_[row][col].state === null) {
        return false;
      }
    }
  }
  return true;
}

export function fullLogicSolve(newGrid) {
  const stats = new SudokuStats();
  newGrid = initialCandidates(newGrid);
  const unsolvedGrid = cloneDeep(newGrid);

  stats.freeCells = getFreeCells(newGrid);

  let finishFlag = false;

  while (!finishFlag) {
    // newGrid = updateCandidates(newGrid);
    let stepResults = handleStep(newGrid, stats);
    stats.addScore(stepResults.score);
    finishFlag = stepResults.finished;
  }
  stats.solvable = isSolved(newGrid);
  console.log("SUDOKU STATS");
  console.log(stats);

  return {
    grid: unsolvedGrid,
    isSolved: stats.solvable,
    score: stats.score,
  };
}

export class SudokuStats {
  constructor() {
    this.score = 0;
    this.solvable = null;
    this.hiddenSingles = 0;
    this.directPointing = 0;
    this.directClaiming = 0;
    this.nakedSingles = 0;
    this.nakedPairs = 0;
    this.freeCells = 0;
  }

  addScore(score_) {
    this.score = this.score + score_;
  }

  addHiddenSingle() {
    this.hiddenSingles++;
  }
  addDirectPointing() {
    this.directPointing++;
  }
  addDirectClaiming() {
    this.directClaiming++;
  }
  addNakedSingle() {
    this.nakedSingles++;
  }
  addNakedPair() {
    this.nakedPairs++;
  }
}
